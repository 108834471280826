import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import Layout from "@/components/layout"
import Helmet from "react-helmet"
import { PageWrapper } from "@/pages/dokumenty-prawne/index"

export default () => (
  <>
    <Helmet>
      <title>
        Poleć mieszkanie w inwestycji WILLBUD i odbierz 3000 zł | Regulamin
        Programu | WILLBUD
      </title>
    </Helmet>
    <Layout>
      <PageWrapper>
        <Container>
          <Row>
            <Col>
              <h1>
                <small>Regulamin Programu</small>
                <br />
                Poleć mieszkanie w&nbsp;inwestycji WILLBUD i&nbsp;odbierz
                3000&nbsp;zł
                <br />
              </h1>

              <section>
                <iframe
                  src="/dokumenty/14_06_2023_WILLBUD_REGULAMIN_3000.pdf#view=fitH"
                  width="100%"
                  height="100%"
                  style={{ border: "1px solid #e0e0e0", minHeight: "80vh" }}
                  title="Regulamin"
                />
              </section>
              <hr />
              <ul>
                <li>
                  <a
                    href="/dokumenty/14_06_2023_WILLBUD_REGULAMIN_3000.pdf"
                    target={`_blank`}
                  >
                    Regulamin Programu <small>[PDF]</small>
                  </a>
                </li>
                <li>
                  <a
                    href="/dokumenty/WILLBUD-formularz-3000-17.03.2023.pdf"
                    target={`_blank`}
                  >
                    Formularz udziału w Programie <small>[PDF]</small>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </PageWrapper>
    </Layout>
  </>
)
